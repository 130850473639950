@tailwind base;
@tailwind components;
@tailwind utilities;

.uploadcare--widget {
  display: block;

  .uploadcare--widget__button_type_open {
    width: 100%;
    background-color: #157cfc;
    cursor: pointer;
    font-size: 14px;
    padding: 12px;
    outline: none;
    font-weight: 500;
  }
  .uploadcare--widget__button_type_open:hover {
    background-color: #0f5da3;
  }
}

.uploadcare--button_primary {
  background-color: #157cfc;
  color: #fff;
  cursor: pointer;
}

.w-md-editor-text-pre > code,
.w-md-editor-text-input {
  font-size: 14px !important;
  line-height: 18px !important;
}


.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
